.messageHeader {
    font-weight: lighter;
    padding: 10px;
    margin-bottom: 10px;
}
.message_fields > div {
    padding: 10px;
}
.message_fields > div > input {
padding: 5px;
width: 200px;
}
.message_text_area {
padding: 10px;
}
.message_submit {
padding-left: 10px;
}
.message_submit > button {
padding: 10px;
border: 1px solid orangered;
border-radius: 5px;
background-color: orangered;
color: white;
}
#msg {
    display: none;
    font-weight: bolder;
    color: red;
}

@media only screen and (max-width: 600px) {
    .message_fields > div > input {
        width: 100%;
    }
    textarea {
        width: 100%;
    }
}