.gallaryHeader {
    font-weight: lighter;
    padding: 10px;
    margin-bottom: 10px;
}
#images-wrapper {    
    line-height: 0;       
     -webkit-column-count: 5;    
     -webkit-column-gap: 0px;    
     -moz-column-count: 5;
     -moz-column-gap: 0px;
      column-count: 5;    
      column-gap: 0px;    
 }  
 #images-wrapper > img {    
    width: 100% !important;    
    height: auto !important; 
 }  
 #images-wrapper{    
    display:inline-block;    
    margin-right: auto;    
    margin-left: auto;  
 }
 @media (max-width: 1000px) {
    #images-wrapper {
    -moz-column-count:    3;
    -webkit-column-count: 3;
    column-count:         3;
    }
   }  
 @media (max-width: 800px) {
    #images-wrapper {
    -moz-column-count:    2;
    -webkit-column-count: 2;
    column-count:         2;
    }
   }  
 @media (max-width: 400px) {
    #images-wrapper {
    -moz-column-count:    1;
    -webkit-column-count: 1;
     column-count:        1;
    }
   }