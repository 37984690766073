@import url('https://fonts.googleapis.com/css?family=Josefin+Sans&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  font-family: 'Josefin Sans', sans-serif;
}

body{
   background-color: #f3f5f9;
}

.btn {
  background-color: orangered;
  border: none;
  color: white;
  padding: 12px 16px;
  font-size: 16px;
  z-index: 5;
  height: 40px;
  position: fixed;
}

.svg {
  width: 10px;
}

.clickedLinkEffect {
  color: white;
}

.wrapper{
  display: flex;
  flex-direction: row;
  scroll-behavior: auto;
  position: relative;
}

.wrapper .sidebar{
  width: 200px;
  height: 100%;
  background: white; /* Navbar background color */
  padding: 30px 0px;
  position: fixed;
}

.wrapper .sidebar .logo {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10%;
}

.wrapper .sidebar .logo img {
    width: 70px;
    border-radius: 50px;
    margin-bottom: 20px;
}


.wrapper .sidebar ul li{
  padding: 15px;
  /*border-bottom: 0.1px solid orangered;  Border color */
  /*border-top: 1px solid orangered;  Border color */
}    

.wrapper .sidebar ul li a{
  color: orangered; /* Text color */
  display: block;
}

.wrapper .sidebar ul li a .fas{
  width: 25px;
}

.clickedEffect {
  background-color: orangered;
}
.wrapper .sidebar ul li:hover{
  background-color: rgb(214, 57, 0) ;  /* Nav element color on hover */
}
    
.wrapper .sidebar ul li:hover a{
  color: #fff;  /* Text color on hover */
}

/*.wrapper .sidebar .social_media{
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
}

.wrapper .sidebar .social_media a{
  display: block;
  width: 40px;
  background: #594f8d;
  height: 40px;
  line-height: 45px;
  text-align: center;
  margin: 0 5px;
  color: #bdb8d7;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}*/

/* ---- CONTENT ----- */

.wrapper .main_content{
  width: 100%;
  margin-top: 30px;
}

.wrapper .main_content .header{
  padding: 20px;
  background: #fff;
  color: #717171;
  border-bottom: 1px solid #e0e4e8;
}

.wrapper .main_content .info{
  margin: 20px;
  color: #717171;
  line-height: 25px;
}

.wrapper .main_content .info div{
  margin-bottom: 20px;
}

.responsiveContent {
  margin-left: 200px;
}
.responsiveMenu {
  display: block;
  transition: width 2s;
}
@media (max-height: 500px){
  .social_media{
    display: none !important;
  }
}

@media only screen and (max-width: 600px) {
  .responsiveContent{
    margin-left: 0px;
  }
}