.certificationsHeader {
    font-weight: lighter;
    padding: 10px;
    margin-bottom: 10px;
}

.certificate_name {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
}

.certificate_name > h2 {
    margin-right: 10px;
}

.certificate_name > a {
    text-decoration: none;
    color: white;
    background-color: orangered;
    padding: 5px;
    border: 2px solid orangered;
    border-radius: 5px;
}

.certificate_name > a:hover {
    color:orangered;
    background-color: white;
    transition-duration: 0.5s;
}