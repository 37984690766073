.feeHeader {
    font-weight: lighter;
    padding: 10px;
    margin-bottom: 10px;
}

.feed {
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 15px 10px 0px 10px;
    border: 1px solid orangered;
    border-radius: 10px;
}
.feed > div > p {
    width: fit-content;
}
.client {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0px;
    padding: 5px;
    width: 30%;
    border-right: 1px solid orangered;
}

.client > h3 {
    font-weight: lighter;
}
.feedback {
    padding: 5px;
    margin: 0px;
    width: 70%;
}

@media only screen and (max-width: 600px) {
    .feed {
        flex-direction: column;
    }
    .client, .feedback {
        width: 100%;
        border: none;
    }
}