.samples {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px;
    border: 1px solid orangered;
    border-radius: 5px;
}

.sampleHeader {
    font-weight: lighter;
    padding: 10px;
    margin-bottom: 10px;
}
.samples > p {
    font-size: 1.2em;
    width: 95%;
    font-weight: bold;
}

.samples > p > em {
    font-size: 0.7em;
    color: orangered;
    border: 2px solid orangered;
    padding: 3px;
    border-radius: 10px;
}

.samples > a {
    text-decoration: none;
    color: white;
    background-color: orangered;
    padding: 5px;
    border: 2px solid orangered;
    border-radius: 5px;
}

.samples > a:hover {
    color:orangered;
    background-color: white;
    transition-duration: 0.5s;
}

@media only screen and (max-width: 600px) {
    .samples {
        flex-direction: column;
        align-items: flex-end;
    }

    .samples > a{
        margin: 5px;
    }
}