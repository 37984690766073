.experienceHeader {
    font-weight: lighter;
    padding: 10px;
    margin-bottom: 10px;
}
.experiences {
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 15px 10px 0px 10px;
    border: 1px solid orangered;
    border-radius: 10px;
}

.job {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0px;
    padding: 5px;
    width: 50%;
    border-right: 1px solid orangered;
}

.job > h3 {
    font-weight: lighter;
}

.duration{
    padding: 5px;
    border-right: 1px solid orangered;
    margin: 0px;
    width: 30%;
}

.type{
    padding: 5px;
    margin: 0px;
    width: 20%;
}

@media only screen and (max-width: 600px) {
    .experiences {
        flex-direction: column;
    }
    .job, .duration, .type {
        width: 100%;
        border: none;
    }
}