.greetings {
    margin: 0;
    padding: 10px 10px 10px 0px;
}
.status {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}
.status > div {
    width: 25%;
    height: 100px;
    background-color: aqua;
    padding: 10px;
}
.status > div:nth-child(1){
    background-color: #200E3A;
}
.status > div:nth-child(2){
    background-color: #38419D;
}
.status > div:nth-child(3){
    background-color: #3887BE;
}
.status > div:nth-child(4){
    background-color: #52D3D8;
}
.status > div > p:nth-child(2){
    font-size: 1.6em;
    color: white;
}
.status > div > p {
    font-size: 0.9em;
    color: white;
}
.overview {
    display: flex;
    justify-content: center;
    flex-direction: row;
}
.overview > div > div > p {
    font-size: 1.2em;
    white-space: break-spaces;
}
.overview:nth-child(1){
    width: 60%;
}
.profile {
    width: 40%;
    padding: 20px;
    border-left: 1px solid orangered;
}
.profile > div > img {
    width: 200px;
}
.profile > div:nth-child(2) > div > p {
    color: orangered;
    white-space: break-spaces;
}

.profile > div  > p {
    font-size: 1.2em;
}
.color {
    color: orangered;
}
.SocialLinks {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    height: 30px;
    padding: 20px;
}

.SocialLinks > div {
    width: 30px;
    margin: 10px;
}

.SocialLinks > div > a > svg {
    width: 100%;
}
@media only screen and (max-width: 600px) {
    .status {
        flex-direction: column;
        padding: 0px;
    }
    .status > div {
        width: 100%;
    }
    .status > div > p {
        font-size: medium;
    }
    .overview {
        flex-direction: column-reverse;
    }
    .profile {
        width: 100%;
        padding: 0px;
        border: none;
    }
}